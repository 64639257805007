import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Row, Card, CardBody, CardHeader, Col, Container } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {  FaEye, FaEyeSlash } from "react-icons/fa";
import { API_ENDPOINTS } from "../helper";

const SendAdmin = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => setModal(!modal);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(API_ENDPOINTS.WITHDRAW_LIST);
      if (response.status) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const postFormik = async (values) => {
    setIsLoading(true);
    try {
      const postData = {
        name: values.name,
        adminAddress: values.address,
        password: values.password,
      };
      const response = await axios.post(API_ENDPOINTS.SEND_TO_ADMIN, postData);
      if (response.data.status) {
        fetchData();
        setModal(false);
        addFormik.resetForm();
        toast.success(response.data.message);
      } else {
        setModal(true);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addFormik = useFormik({
    initialValues: {
      password: "",
      address: "",
      name: "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Address is required"),
      password: Yup.string().required("Password is required"),
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      postFormik(values);
    },
  });

  const columns = [
    { name: "S.No", selector: (row, index) => index + 1, sortable: true },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Amount", selector: (row) => row.amount, sortable: true },
    { name: "Balance", selector: (row) => row.bal, sortable: true },
    { name: "Transaction Hash", selector: (row) => row.getTxHash, sortable: true },
    { name: "From Address", selector: (row) => row.fromAddress, sortable: true },
    { name: "Admin Address", selector: (row) => row.adminAddress, sortable: true },
  ];

  return (
    <div>
      <Container className="mt-4">
        {/* Card for the Create Transaction Section */}
        <Card>
          <CardHeader className="bg-secondary text-white">
            <h5 className="mb-0">Send To Admin</h5>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col className="text-end">
                {/* Add Admin Button */}
                <Button color="success" onClick={() => setModal(true)}>
                  Send To Admin
                </Button>
              </Col>
            </Row>

            <DataTable columns={columns} data={data} 
            progressPending={loading} pagination 
            paginationServer 
            persistTableHead={true} />
          </CardBody>
        </Card>
      </Container>

      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop="static">
          <Form onSubmit={addFormik.handleSubmit}>
            <ModalHeader>Send To Admin</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleEmail">Name</Label>
                <Input id="exampleEmail" name="name" autoComplete="off"  placeholder="Enter Name" type="text" value={addFormik.values.name} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.name && addFormik.errors.name ? <div className="error-msg">{addFormik.errors.name}</div> : null}
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Address</Label>
                <Input id="exampleEmail" name="address" autoComplete="off" placeholder="Enter Address" type="text" value={addFormik.values.address} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.address && addFormik.errors.address ? <div className="error-msg">{addFormik.errors.address}</div> : null}
              </FormGroup>

              <FormGroup>
              <Label for="password">Password</Label>
              <div className="input-group">
                <Input
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  value={addFormik.values.password}
                  onBlur={addFormik.handleBlur}
                  onChange={addFormik.handleChange}
                />
                <span
                  className="input-group-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FaEyeSlash style={{ fontSize: "20px", cursor: "pointer" }} />
                  ) : (
                    <FaEye style={{ fontSize: "20px", cursor: "pointer" }} />)}
                </span>
              </div>
              {addFormik.touched.password && addFormik.errors.password ? (
                <div className="error-msg">{addFormik.errors.password}</div>
              ) : null}
            </FormGroup>
            </ModalBody>
            <ModalFooter>
              {isLoading ? (
                <Button type="onSubmit" color="primary">
                  Loading...
                </Button>
              ) : (
                <Button type="onSubmit" color="primary">
                  Submit
                </Button>
              )}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default SendAdmin;
