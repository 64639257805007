import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Container, Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../helper";

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    fetchData()
  },[])
  const addFormik = useFormik({
    initialValues: {
      time: "",
      address: "",
      name: "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Address is required"),
      time: Yup.string().required("Time is required"),
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      postFormik(values);
    },
  });
  const toggle = () => setModal(!modal);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(API_ENDPOINTS.GET_ADMIN_ADDRESS);
      if (response.status) {
        setData(response?.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const postFormik = async (values) => {
    setIsLoading(true);
    try {
      const postData = {
        adminName: values.name,
        adminAddress: values.address,
        withdrawTime: values.time,
      };
      const response = await axios.post(API_ENDPOINTS.CREATE_ADMIN_ADDRESS, postData);
      if (response.status) {
        fetchData();
        setModal(false);
        addFormik.resetForm();
        toast.success(response.message);
      } else {
        setModal(true);
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { name: "S.No", selector: (row, index) => index + 1, sortable: true },
    { name: "Name", selector: (row) => row.adminName, sortable: true },
    { name: "Address", selector: (row) => row.adminAddress, sortable: true },
    { name: "Withdraw Time", selector: (row) => row.withdrawTime, sortable: true },
  ];

  return (
    <div>
      <Container className="mt-4">
        <Card>
          <CardHeader className="bg-secondary text-white">
            <h5 className="mb-0">Add Admin</h5>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col className="text-end">
                {/* Add Admin Button */}
                <Button color="success" onClick={() => setModal(true)}>
                  Add Admin
                </Button>
              </Col>
            </Row>

            <DataTable columns={columns} data={data} 
            progressPending={loading} 
            pagination paginationServer
            
            persistTableHead={true} />
          </CardBody>
        </Card>
      </Container>

      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop="static">
          <Form onSubmit={addFormik.handleSubmit}>
            <ModalHeader>Add Admin</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleEmail">Name</Label>
                <Input id="exampleEmail" name="name" autoComplete="off" placeholder="Enter Name" type="text" value={addFormik.values.name} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.name && addFormik.errors.name ? <div className="error-msg">{addFormik.errors.name}</div> : null}
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Address</Label>
                <Input id="exampleEmail" name="address" autoComplete="off" placeholder="Enter Address" type="text" value={addFormik.values.address} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.address && addFormik.errors.address ? <div className="error-msg">{addFormik.errors.address}</div> : null}
              </FormGroup>

              <FormGroup>
                <Label for="exampleEmail">Time</Label>
                <Input id="exampleEmail" name="time" autoComplete="off" placeholder="Enter Time" type="text" value={addFormik.values.time} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.time && addFormik.errors.time ? <div className="error-msg">{addFormik.errors.time}</div> : null}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              {isLoading ? (
                <Button type="onSubmit" color="primary">
                  Loading...
                </Button>
              ) : (
                <Button type="onSubmit" color="primary">
                  Submit
                </Button>
              )}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default App;
