const BASE_URL = "http://192.168.1.20:7000";

export const API_ENDPOINTS = {
    CREATE_LIST: `${BASE_URL}/api/createList`,
    GET_ADDRESS_HISTORY_DATA: `${BASE_URL}/api/getAddressHistoryData`,
    GET_ADMIN_ADDRESS: `${BASE_URL}/api/getAdminAddress`,
    CREATE_ADMIN_ADDRESS: `${BASE_URL}/api/createAdminAddress`,
    WITHDRAW_LIST: `${BASE_URL}/api/withdrawList`,
    SEND_TO_ADMIN: `${BASE_URL}/api/sendToAdmin`,
    
    // GET_USER_BY_ID: (id) => `${BASE_URL}/users/${id}`,
  };