import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Col, Row, Form, FormGroup, Label, Input, Container, CardHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../helper";

const Createtransaction = () => {
  const toggle = () => setModal(!modal);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(10); 


  const fetchData = async (page,perPage) => {
    setLoading(true);
    try {
      const postData = {
        page,
        limit: perPage
      }
      const response = await axios.post(API_ENDPOINTS.GET_ADDRESS_HISTORY_DATA,postData);
      if(response.status){
        setLoading(false)
        setData(response.data.data);
        setCurrentPage(response.data.pagination.currentPage);
        setTotalCount(response.data.pagination.totalCount);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const postFormik = async (values) => {
    setIsLoading(true);
    try {
      const response = await axios.post(API_ENDPOINTS.CREATE_LIST, values);
      if (response?.data?.status) {
        fetchData();
        setModal(false);
        setIsLoading(false);
        addFormik.resetForm();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        setModal(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(error.message);
      setModal(true);
    } finally {
      setIsLoading(false);
    }
  };

   useEffect(() => {
    fetchData(currentPage, rowsPerPage);
}, [currentPage, rowsPerPage]);

const handlePageChange = (page) => {
  if (page !== currentPage) {
    setCurrentPage(page);
  }
};

const handleRowsPerPageChange = (newPerPage, page) => {
  if (newPerPage !== rowsPerPage || page !== currentPage) {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  }
};


  const addFormik = useFormik({
    initialValues: {
      amount: "",
      address: "",
      limit: "",
      name: "",
      pvtKey: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .typeError("Amount must be a number")
        .required("Amount is required")
        .positive("Amount must be a positive number")
        .min(0.1, "Amount must be at least 0.1")
        .test("is-decimal", "Amount must have at most 2 decimal places", (value) => /^\d+(\.\d{1,2})?$/.test(value)),
      address: Yup.string().required("Address is required"),
      limit: Yup.string().required("Limit is required"),
      name: Yup.string().required("Name is required"),
      pvtKey: Yup.string().required("PVTkey is required"),
    }),
    onSubmit: (values) => {
      postFormik(values);
    },
  });

  const columns = [
    { name: "S.No", selector: (row) => row.serial, sortable: true },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Amount", selector: (row) => row.amount, sortable: true },
    { name: "Balance", selector: (row) => row.bal, sortable: true },

    { name: "Transaction Hash", selector: (row) => row.getTxHash, sortable: true },
    { name: "Address", selector: (row) => row.address, sortable: true },
    { name: "Admin Address", selector: (row) => row.adminAddress, sortable: true },
  ];

  return (
    <div>
      <Container className="mt-4">
        {/* Card for the Create Transaction Section */}
        <Card>
          <CardHeader className="bg-secondary text-white">
            <h5 className="mb-0">Create Transaction</h5>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col className="text-end">
                {/* Add Admin Button */}
                <Button color="success" onClick={() => setModal(true)}>
                  Add Admin
                </Button>
              </Col>
            </Row>
            <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            persistTableHead
        />
          </CardBody>
        </Card>
      </Container>

      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop="static">
          <Form onSubmit={addFormik.handleSubmit}>
            <ModalHeader>Create Transaction</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleEmail">Name</Label>
                <Input id="exampleEmail" name="name" autoComplete="off" placeholder="Enter Name" type="text" value={addFormik.values.name} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.name && addFormik.errors.name ? <div className="error-msg">{addFormik.errors.name}</div> : null}
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Address</Label>
                <Input id="exampleEmail" name="address" autoComplete="off" placeholder="Enter Address" type="text" value={addFormik.values.address} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.address && addFormik.errors.address ? <div className="error-msg">{addFormik.errors.address}</div> : null}
              </FormGroup>

              <FormGroup>
                <Label for="exampleEmail">Amount</Label>
                <Input id="exampleEmail" name="amount" autoComplete="off" placeholder="Enter Amount" type="text" value={addFormik.values.amount} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.amount && addFormik.errors.amount ? <div className="error-msg">{addFormik.errors.amount}</div> : null}
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">PVT key</Label>
                <Input id="exampleEmail" name="pvtKey" autoComplete="off" placeholder="Enter PVT Key" type="text" value={addFormik.values.pvtKey} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.pvtKey && addFormik.errors.pvtKey ? <div className="error-msg">{addFormik.errors.pvtKey}</div> : null}
              </FormGroup>

              <FormGroup>
                <Label for="exampleEmail">Limit</Label>
                <Input id="exampleEmail" name="limit" autoComplete="off"  placeholder="Enter Limit" type="text" value={addFormik.values.limit} onBlur={addFormik.handleBlur} onChange={addFormik.handleChange} />

                {addFormik.touched.limit && addFormik.errors.limit ? <div className="error-msg">{addFormik.errors.limit}</div> : null}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              {isLoading ? (
                <Button type="onSubmit" color="primary">
                  Loading...
                </Button>
              ) : (
                <Button type="onSubmit" color="primary">
                  Submit
                </Button>
              )}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Createtransaction;
