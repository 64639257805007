import "./App.css";
import { Nav, NavItem, NavLink, Navbar, Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Createtransaction from "./components/Createtransaction";
import AdminPage from "./components/AdminPage";
import SendAdmin from "./components/SendAdmin";

function App() {
  return (
    <div className="container mt-2">
      <ToastContainer />
      <Router>
        <div>
          <Navbar color="light" light expand="md">
            <Container>
              <Nav className="me-auto" navbar>
                <NavItem>
                  <NavLink href="/">Create Transaction</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/add-admin">Add Admin</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/send-admin">Send To Admin</NavLink>
                </NavItem>
              </Nav>
            </Container>
          </Navbar>
          <Routes>
            <Route path="/" element={<Createtransaction />} />
            <Route path="/add-admin" element={<AdminPage />} />
            <Route path="/send-admin" element={<SendAdmin />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
